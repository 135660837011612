import qs from 'qs'
import Request from 'superagent'
import { captureException } from '@sentry/browser'

import get from 'lodash/get'
import has from 'lodash/has'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'

import { getConfig } from 'Store/Selectors/app'

import { API, IS_PRODUCTION } from 'Config/app'

const sendMethod = HTTPMethod =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? 'send'
    : 'query'

const sendArguments = (HTTPMethod, query) =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? JSON.stringify(query)
    : qs.stringify(query, { arrayFormat: 'brackets' })

const defaultOptions = {
  endpoint: '',
  method: 'GET',
  query: {},
  headers: {},
  types: null,
  cache: true,
  apiVersion: 2,
  ssr: false,
}

const absoluteUrl = new RegExp('^(?:[a-z]+:)?//', 'i')

const processOptions = (options, config) => {
  const configHost = get(config, 'host')
  const isSSR = get(options, 'ssr', false)

  const {
    endpoint,
    method,
    query,
    headers,
    payload,
    types,
    cache,
    apiVersion,
  } = merge(
    {},
    {
      ...defaultOptions,
    },
    options,
  )

  let url = configHost
    ? `http${IS_PRODUCTION ? 's' : ''}://${configHost}/api`
    : API.URL

  // If the request is occuring during SSR, use the local server url
  // and set the host header to the config host so we get the right
  // subdomain config
  if (isSSR) {
    url = API.SSR_URL
    headers.host = configHost
  }

  // Tell our apiHandler not to use the api-cache if configured
  if (!cache) {
    headers['x-no-api-cache'] = true
  }

  // Append basic auth to the headers if it's an internal request (SSR)
  if (config?.basicAuth && isSSR) {
    headers.authorization = config.basicAuth
  }

  const HTTPMethod = method.toLowerCase()

  const fullUrl = absoluteUrl.test(endpoint)
    ? endpoint
    : `${url}/v${apiVersion}${endpoint}`

  return {
    HTTPMethod,
    fullUrl,
    query,
    headers,
    payload,
    types,
  }
}

export default options => (dispatch, getState) => {
  const config = getConfig(getState())

  const { HTTPMethod, fullUrl, query, headers, payload, types } =
    processOptions(options, config)

  const request = Request[HTTPMethod](fullUrl)

  request[sendMethod(HTTPMethod)](sendArguments(HTTPMethod, query))

  if (HTTPMethod.toLowerCase() === 'post') {
    request.send(payload)
  }

  if (has(types, 'REQUEST')) {
    dispatch({
      type: types.REQUEST,
      payload,
      request,
    })
  }

  request
    .set({
      'Content-Type': 'application/json',
      ...headers,
    })
    .on('progress', event => {
      if (event.direction === 'upload') {
        if (has(types, 'PROGRESS') && has(event, 'percent')) {
          dispatch({
            type: types.PROGRESS,
            percent: event.percent,
            payload,
          })
        }
      }
    })

  const response = new Promise(resolve => {
    request.end((error, data = {}) => {
      if (isEmpty(data) || data.body === null) {
        merge(data, { body: { data: [] } })
      }

      const { status } = data

      if (error) {
        const failureData = {
          ok: false,
          payload,
          error,
          data,
          status,
        }

        if (has(types, 'FAILURE')) {
          dispatch({ type: types.FAILURE, ...failureData })
        }

        captureException(failureData)
        resolve(failureData)
      } else {
        const successData = {
          ok: true,
          data: get(data, 'body.data'),
          payload: { ...payload },
          status,
        }

        if (has(types, 'SUCCESS')) {
          dispatch({ type: types.SUCCESS, ...successData })
        }

        resolve(successData)
      }
    })
  })

  response.abort = () => {
    if (request && typeof request.abort === 'function') {
      request.abort()
    }
  }

  return response
}
