import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import debounce from 'lodash/debounce'
import flatMap from 'lodash/flatMap'
import first from 'lodash/first'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'

import { withRouter } from 'react-router'
import { withAppContext } from 'Services/Context'

import * as Utils from 'Services/Utils'

import { _ } from 'Services/I18n'

import Labels from 'Constants/labels'
import { PUBLIC_PATHS } from 'Constants/paths'
import { MARKETPLACE_SERVICE_TYPE } from 'Constants/ids'

import {
  processLocations,
  processMerchants,
  processCategories,
  processFeaturedCategories,
  processFeaturedLocations,
  processBucketForSearchPathParams,
  processBucketForOnlineSearchPathParams,
  processBucketForAllResultsSearchPathParams,
} from 'Services/Utils/merchants'
import { initLocationOptions } from './constants'

import SearchSelect from '../SearchSelect'

import {
  SearchContainer,
  InputContainer,
  FindButton,
  SearchIcon,
  FindButtonLabel,
} from './styles'

class MerchantsSearch extends PureComponent {
  state = {
    options: [],
    search: '',
    disabledSearch: false,
    disabledCategorySearch: false,
    categorySearch: '',
    categoryOptions: [],
  }

  inputRef = React.createRef()

  debounceSearch = debounce(search => this.handleSearch(search), 400, {
    leading: false,
    trailing: true,
  })

  debounceCategorySearch = debounce(
    categorySearch => this.handleCategorySearch(categorySearch),
    400,
    {
      leading: false,
      trailing: true,
    },
  )

  componentDidMount() {
    const { history, onSetCategoryValue, onSetSearchValue } = this.props

    if (history.pathname !== PUBLIC_PATHS.CATEGORY()) {
      onSetCategoryValue(null)
    }
    onSetSearchValue(null)
  }

  handleSearch = search => {
    const { baseTheme, onLoadSuggestions } = this.props
    const marketplaceType = get(baseTheme, 'marketplace_type')
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')

    onLoadSuggestions({
      term: search,
      serviceType: marketplaceServiceType,
    }).then(restaurants => {
      const { data } = restaurants

      const region = processLocations(
        get(data, 'location.region', []),
        'region',
      )

      const suburb = processLocations(
        get(data, 'location.suburb', []),
        'suburb',
      )

      const locations = {
        ...region,
        options: [...region.options, ...suburb.options],
      }

      const merchant = processMerchants(get(data, 'merchant', []), 'merchant')

      const options = [
        ...(marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online
          ? initLocationOptions({
              baseTheme,
              marketplaceType,
              marketplaceServiceType,
            })
          : []),
        locations,
        merchant,
      ]

      this.setState({
        options,
        disabledSearch: false,
      })
    })
  }

  handleInputChange = (search, { action }) => {
    if (search || action === 'input-change') {
      this.setState({ search })
    }
    if (search.length < 2) return
    this.setState({ disabledSearch: true })
    this.debounceSearch(search)
  }

  handleSelectionOption = async option => {
    const { baseTheme, categoryValue, history, onSetSearchValue } = this.props
    const marketplaceType = get(baseTheme, 'marketplace_type')
    const { categoryId, categoryFullName } =
      Utils.getCategoryProps(categoryValue)

    if (!option) {
      return
    }

    await onSetSearchValue({
      ...option,
      label: get(option, 'value.bucket_name', ''),
    })

    // Handle near me selection
    if (get(option, 'value') === 'near') {
      history.push(get(option, 'metadata.path'))
      return
    }

    // Handle online me selection
    if (get(option, 'value') === 'online') {
      history.push(
        PUBLIC_PATHS.SEARCH_QUERY({
          base: 'search',
          bucketType: 'online',
          categoryId,
          categoryFullName,
          currentPage: 1,
        }),
      )
      return
    }

    if (get(option, 'type') === 'merchant') {
      history.push(
        PUBLIC_PATHS.MERCHANT({
          id: get(option, 'value.merchant_token'),
          slug: get(option, 'value.merchant_slug'),
          marketplaceType,
        }),
        {
          ...history.location.state,
          prevPath: history.location.pathname,
        },
      )
      return
    }

    const queryParams = {
      ...processBucketForSearchPathParams(
        { ...option.value, bucket_type: option.type },
        1,
      ),
      categoryId,
      categoryFullName,
    }
    history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams))
  }

  handleCategorySearch = categorySearch => {
    const { baseTheme, onLoadCategorySuggestions } = this.props
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')

    this.setState({ disabledCategorySearch: true })

    onLoadCategorySuggestions({
      term: categorySearch,
      serviceType: marketplaceServiceType,
    }).then(({ data }) => {
      const categories = processCategories(
        get(data, 'categories', []),
        'categories',
      )

      const merchant = processMerchants(get(data, 'merchants', []), 'merchant')

      const categoryOptions = [categories, merchant]
      this.setState({ categoryOptions, disabledCategorySearch: false })
    })
  }

  handleInputCategoryChange = (categorySearch, { action }) => {
    this.setState({ categorySearch })

    if (categorySearch || action === 'input-change') {
      this.setState({ categorySearch })
    }
    if (categorySearch.length < 2) return
    this.setState({ disabledCategorySearch: true })
    this.debounceCategorySearch(categorySearch)
  }

  handleSelectionCategoryOption = async option => {
    const { baseTheme, history, onSetCategoryValue } = this.props
    const marketplaceType = get(baseTheme, 'marketplace_service_type')

    await onSetCategoryValue(option)

    if (get(option, 'type') === 'merchant') {
      history.push(
        PUBLIC_PATHS.MERCHANT({
          id: get(option, 'value.merchant_token'),
          slug: get(option, 'value.merchant_slug'),
          marketplaceType,
        }),
        {
          prevPath: history.location.pathname,
        },
      )
      return
    }

    this.handleFind()
  }

  handleBlurInput = () => {}

  handleFocusSearchInput = () => {
    const { baseTheme } = this.props

    const marketplaceType = get(baseTheme, 'marketplace_type')
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')

    // Hide 'search near me' option if service type is online
    const options = marketplaceServiceType
      ? initLocationOptions({
          baseTheme,
          marketplaceType,
          marketplaceServiceType,
        })
      : []

    this.setState({ options })
  }

  handleFocusCategoryInput = async () => {
    const { categoryValue, featuredCategories, onLoadFeaturedCategories } =
      this.props

    let categories = []

    if (!isEmpty(featuredCategories)) {
      categories = featuredCategories
    } else {
      const { data } = await onLoadFeaturedCategories()
      categories = data
    }

    const categoryOptions = processFeaturedCategories({
      categories,
      type: 'categories',
      categoryValue,
    })

    this.setState({ categoryOptions: [categoryOptions] })
  }

  handleFind = () => {
    const { baseTheme, history, categoryValue } = this.props
    const { options, categoryOptions } = this.state
    const option = first(compact(flatMap(options, 'options')))
    const categoryOption = first(compact(flatMap(categoryOptions, 'options')))
    const marketplaceType = get(baseTheme, 'marketplace_type')
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')

    if (marketplaceServiceType === MARKETPLACE_SERVICE_TYPE.online) {
      // If first selected category option is a merchant
      if (get(categoryOption, 'type') === 'merchant') {
        history.push(
          PUBLIC_PATHS.MERCHANT({
            id: get(categoryOption, 'value.merchant_token'),
            slug: get(categoryOption, 'value.merchant_slug'),
            marketplaceType,
          }),
          {
            prevPath: history.location.pathname,
          },
        )
        return
      }

      // Force selection of first item from options if no option selected and isn't featured
      if (
        !categoryValue &&
        categoryOptions.length &&
        categoryOptions[0].options.length &&
        !categoryOptions[0].featured
      ) {
        this.handleSelectionCategoryOption(categoryOption)
        return
      }

      const queryParams = processBucketForOnlineSearchPathParams({
        ...get(categoryValue, 'value'),
      })

      history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams))
      return
    }

    if (!option && !categoryOption) {
      const params = processBucketForAllResultsSearchPathParams()
      history.push(PUBLIC_PATHS.SEARCH_QUERY(params))
      return
    }

    if (!option && categoryOption) {
      let queryParams = processBucketForSearchPathParams(
        {
          ...(categoryValue?.value ?? categoryOption.value),
          bucket_type: 'category',
        },
        1,
      )

      // If there's no category filter selected, show bucket for all results instead
      if (!categoryValue) {
        queryParams = processBucketForAllResultsSearchPathParams()
      }

      history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams))
      return
    }

    if (get(option, 'type') === 'merchant') {
      history.push(
        PUBLIC_PATHS.MERCHANT({
          id: get(option, 'value.merchant_token'),
          slug: get(option, 'value.merchant_slug'),
          marketplaceType,
        }),
        {
          ...history.location.state,
          prevPath: history.location.pathname,
        },
      )
      return
    }

    const queryParams = processBucketForSearchPathParams(
      { ...option.value, bucket_type: option.type },
      1,
    )
    history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams))
  }

  handleFocus = () => {
    try {
      this.inputRef.current.focus()
    } catch (error) {
      //
    }
  }

  render() {
    const {
      options,
      search,
      disabledSearch,
      disabledCategorySearch,
      categoryOptions,
      categorySearch,
    } = this.state
    const {
      baseTheme,
      baseTheme: { colors },
      isFeaturedCategoriesLoading,
      isFeaturedLocationsLoading,
      categoryValue,
      searchValue,
      featuredLocations,
    } = this.props
    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')
    const serviceCategory = get(baseTheme, 'service_category') ? 1 : 0

    const searchPlaceholder = get(
      baseTheme,
      `labels.${Labels.PLACEHOLDER_HOME_SEARCH}`,
    )

    const categorySearchPlaceholder = get(
      baseTheme,
      `labels.${Labels.PLACEHOLDER_HOME_SEARCH_CATEGORY}`,
    )

    const featureLocationOptions = processFeaturedLocations({
      locations: featuredLocations,
    })

    const singleInput =
      marketplaceServiceType === MARKETPLACE_SERVICE_TYPE.online ||
      !serviceCategory
        ? 1
        : 0

    const showSearchLabel = marketplaceServiceType && serviceCategory ? 1 : 0

    return (
      <SearchContainer singleinput={singleInput}>
        <InputContainer borderradius={roundedBorder} singleinput={singleInput}>
          {serviceCategory ? (
            <SearchSelect
              filterOption={() => true}
              isDisabledSelect={
                disabledCategorySearch || isFeaturedCategoriesLoading
              }
              isLoading={disabledCategorySearch || isFeaturedCategoriesLoading}
              mb={[3, 3, 0]}
              mr={
                marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online ||
                roundedBorder
                  ? [0, 0, 3]
                  : 0
              }
              options={categoryOptions}
              placeholder={categorySearchPlaceholder}
              value={categorySearch || categoryValue}
              onBlur={this.handleBlurInput}
              onChange={this.handleSelectionCategoryOption}
              onFocus={this.handleFocusCategoryInput}
              onInputChange={this.handleInputCategoryChange}
            />
          ) : null}
          {marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online ? (
            <SearchSelect
              fieldRef={this.inputRef}
              filterOption={() => true}
              isDisabledSelect={disabledSearch || isFeaturedLocationsLoading}
              isLoading={disabledSearch || isFeaturedLocationsLoading}
              options={[...options, featureLocationOptions]}
              placeholder={searchPlaceholder}
              value={search || searchValue}
              onBlur={this.handleBlurInput}
              onChange={this.handleSelectionOption}
              onFocus={this.handleFocusSearchInput}
              onInputChange={this.handleInputChange}
            />
          ) : null}
        </InputContainer>
        <FindButton
          bgcolor={get(colors, 'primary_background')}
          borderradius={roundedBorder}
          disabled={
            disabledSearch ||
            disabledCategorySearch ||
            isFeaturedCategoriesLoading
          }
          singleinput={singleInput}
          textcolor={get(colors, 'primary_text')}
          withlabel={showSearchLabel}
          onClick={this.handleFind}
        >
          <SearchIcon withlabel={showSearchLabel} />
          <FindButtonLabel withlabel={showSearchLabel}>
            {_('common.search')}
          </FindButtonLabel>
        </FindButton>
      </SearchContainer>
    )
  }
}

MerchantsSearch.defaultProps = {
  categoryValue: null,
  featuredCategories: [],
  featuredLocations: [],
  searchValue: null,
}

MerchantsSearch.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  categoryValue: PropTypes.object,
  featuredCategories: PropTypes.array,
  featuredLocations: PropTypes.array,
  history: PropTypes.object.isRequired,
  isFeaturedCategoriesLoading: PropTypes.bool.isRequired,
  isFeaturedLocationsLoading: PropTypes.bool.isRequired,
  searchValue: PropTypes.object,
  onLoadCategorySuggestions: PropTypes.func.isRequired,
  onLoadFeaturedCategories: PropTypes.func.isRequired,
  onLoadSuggestions: PropTypes.func.isRequired,
  onSetCategoryValue: PropTypes.func.isRequired,
  onSetSearchValue: PropTypes.func.isRequired,
}

export default withRouter(withAppContext(MerchantsSearch))
