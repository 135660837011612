import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import Slider from 'react-slick'

import { Box, Flex, Loader as BaseLoader } from 'Components/UI'

const isGridCss = ({ compact }) =>
  compact &&
  css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    width: 100%;
    height: fit-content;
  `

export const Container = styled(Flex).attrs({
  flexDirection: 'column',
  position: 'relative',
})`
  width: 100%;
  gap: 16px;
  transform: translateZ(0);

  ${isGridCss};
`

export const MobileSliderContainer = styled(Slider).attrs({})``

export const LoaderOverlay = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: translateX(0);
  transition: transform 0.2s ease;
  z-index: 100;

  @keyframes pulse-results {
    0%,
    100% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.1;
    }
  }

  & ~ * {
    animation: pulse-results 3s infinite;
  }
`

export const LoaderContainer = styled(Box)`
  background-color: ${themeGet('colors.white')};

  flex-direction: column;
  position: sticky;
  align-items: center;
  overflow: hidden;
  transform: translate(-50%, -50%);

  top: 50%;
  width: 70px;
  height: 70px;
  margin-top: 50px;
  margin-left: 50%;
  padding: 25px;

  border: 1px solid ${themeGet('colors.concrete')};
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`

export const Loader = styled(BaseLoader)`
  width: 100%;
`

export default {}
