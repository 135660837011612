import styled from 'styled-components'
import { height, width } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { mapMarkerGlyph, smileGlyph } from 'Assets/Svg'

import { Flex, Text, Icon } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: ['column', 'row'],
})`
  background-color: ${themeGet('colors.white')};
  width: 100%;
  border-radius: ${themeGet('borderRadius.1')};
  border: 1px solid ${themeGet('colors.lightGrey')};

  overflow: hidden;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    height: inherit;
  }

  ${height};
  ${width};
`

export const Content = styled(Flex).attrs({
  flexDirection: 'column',
  p: 3,
  width: [
    '100%',
    ({ hideImage }) => (hideImage ? '100%' : 'calc(100% - 250px)'),
  ],
})`
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

export const Title = styled(Text).attrs({ is: 'h3', fontSize: [16, 16, 18] })`
  font-weight: 600;
  text-align: center;
  width: 100%;
  line-height: 1.2;
`

export const List = styled(Flex).attrs({
  is: 'ul',
  m: 0,
  p: 0,
  mt: [10, 10, 3],
  flexDirection: 'column',
})`
  list-style: none;
`

export const ListItem = styled(Text).attrs({
  is: 'li',
  fontSize: [14, 14, 16],
  m: 0,
  mt: 2,
  ml: 2,
  p: 0,
  pl: 2,
})`
  align-items: center;
  position: relative;
  letter-spacing: 0.4px;

  &::before {
    background-color: ${themeGet('colors.black')};
    border-radius: 50%;
    content: '';
    position: absolute;
    left: -4px;
    top: 4px;
    height: 4px;
    width: 4px;
  }
`

export const MapIcon = styled(Icon).attrs({
  glyph: mapMarkerGlyph,
  size: 16,
  mr: 1,
})`
  fill: ${themeGet('colors.tealBlue')};
  margin-top: -3px;
`

export const NearSearch = styled(Text).attrs({
  fontSize: [16, 16, 18],
  mt: [22, 22, 4],
})`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  cursor: pointer;
  letter-spacing: 0.5px;
  text-decoration: underline;

  ${MapIcon} {
    fill: ${({ color }) => color || themeGet('colors.tealBlue')};
  }
`

export const ImageHolder = styled(Flex)`
  background-color: ${themeGet('colors.concrete')};
  align-items: center;
  justify-content: center;
  min-height: 240px;
  min-width: 215px;
`

export const NotFoundImage = styled(Icon).attrs({
  glyph: smileGlyph,
  size: 64,
})``
