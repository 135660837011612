import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import SearchResultMap from './SearchResultMap'

const selector = createStructuredSelector({})

const actions = {}

export default connect(selector, actions)(SearchResultMap)
