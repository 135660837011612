import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { Box, Flex } from 'Components/UI'

export const Container = styled(Flex).attrs({
  flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
})`
  gap: 16px;
  width: 100%;
  height: calc(100vh - ${({ paginated }) => (paginated ? '260px' : '250px')});
  height: calc(100dvh - ${({ paginated }) => (paginated ? '260px' : '250px')});
  overflow: hidden;
  position: relative;
  margin-bottom: 0;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    height: calc(100vh - ${({ mobileoffset }) => mobileoffset});
    height: calc(100dvh - ${({ mobileoffset }) => mobileoffset});
    gap: 8px;
  }
`

export const MapContainer = styled(Flex).attrs({})`
  flex: 1;
`

export const ResultsContainer = styled(Box).attrs({
  display: ['block', 'block', 'block', 'flex'],
  width: ['100%', '100%', '100%', '550px'],
})`
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    max-width: unset;
    overflow: hidden;
  }

  .active-map-result {
    padding: 10px;
    border: 3px solid
      ${({ activecolor }) => activecolor || themeGet('colors.black')};
`

export default {}
