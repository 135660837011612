import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import map from 'lodash/map'
import first from 'lodash/first'
import filter from 'lodash/filter'
import find from 'lodash/find'
import debounce from 'lodash/debounce'

import GoogleMapReact from 'google-map-react'

import { GOOGLE_MAP } from 'Config/app'

import { withAppContext } from 'Services/Context'

import Marker from './Marker'

import { Container } from './styles'

const DEFAULT_ZOOM = 10

class Map extends PureComponent {
  handleDragEnd = debounce(
    googleMap => {
      const { onMapDragEnd } = this.props
      const latLng = googleMap.getCenter().toJSON()

      if (onMapDragEnd) {
        onMapDragEnd(latLng)
      }
    },
    500,
    { trailing: true, leading: true },
  )

  getMarkerKey = marker => get(marker, 'token', get(marker, 'service.id'))

  getMarkerLatLng = marker => {
    const location = get(marker, 'location', get(marker, 'service.locations.0'))

    return get(location, 'google_map')
  }

  getItemCenter = marker => marker && this.getMarkerLatLng(marker)

  handleMarkerClick = activeMarker => {
    const { onMarkerClick } = this.props
    onMarkerClick(activeMarker)
  }

  render() {
    const { activeMarker, baseTheme, config, center, markers, userPosition } =
      this.props

    const firstMarker = first(markers)
    const activeCenter = this.getItemCenter(activeMarker)
    const firstCenter = this.getItemCenter(firstMarker)
    const defaultCenter = userPosition || firstCenter || center

    let orderedMarkers = filter(
      markers,
      marker => this.getMarkerKey(activeMarker) !== this.getMarkerKey(marker),
    )

    const currentMarker = find(
      markers,
      marker => this.getMarkerKey(activeMarker) === this.getMarkerKey(marker),
    )

    if (currentMarker) {
      orderedMarkers = [...orderedMarkers, currentMarker]
    }

    return (
      <Container>
        <GoogleMapReact
          bootstrapURLKeys={{ key: get(config, 'gmapsKey') || GOOGLE_MAP.KEY }}
          center={activeCenter || defaultCenter}
          options={{
            scrollwheel: false,
          }}
          yesIWantToUseGoogleMapApiInternals
          zoom={DEFAULT_ZOOM + 4}
          onDragEnd={this.handleDragEnd}
        >
          {map(orderedMarkers, marker => (
            <Marker
              active={
                this.getMarkerKey(activeMarker) === this.getMarkerKey(marker)
              }
              baseTheme={baseTheme}
              key={this.getMarkerKey(marker)}
              lat={this.getItemCenter(marker)?.lat}
              lng={this.getItemCenter(marker)?.lng}
              marker={marker}
              onClick={this.handleMarkerClick}
            />
          ))}
        </GoogleMapReact>
      </Container>
    )
  }
}

Map.defaultProps = {
  activeMarker: null,
  center: {
    // Alice Springs
    lat: -23.698,
    lng: 133.8807,
  },
  markers: [],
  userPosition: null,
  onMapDragEnd: null,
}

Map.propTypes = {
  activeMarker: PropTypes.object,
  baseTheme: PropTypes.object.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  config: PropTypes.object.isRequired,
  markers: PropTypes.arrayOf(PropTypes.object),
  userPosition: PropTypes.object,
  onMapDragEnd: PropTypes.func,
  onMarkerClick: PropTypes.func.isRequired,
}

export default withAppContext(Map)
